<template>
  <div class="app-login-container">
    <div class="login-form-box">
      <div class="item box-left"></div>
      <div class="item login-form">
        <div class="form-main">
          <p class="form-header">智能管理平台</p>

          <a-form-model ref="form" layout="inline" :model="form">
            <a-form-model-item>
              <a-input
                v-model="form.username"
                :rules="[{ required: true, message: '请输入用户名' }]"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input-password
                autocomplete="new-password"
                v-model="form.password"
                :rules="[{ required: true, message: '请输入密码' }]"
              >
                <a-icon
                  slot="prefix"
                  type="user"
                  style="color: rgba(0, 0, 0, 0.25)"
                />
              </a-input-password>
            </a-form-model-item>
            <a-form-model-item>
              <a-checkbox v-model="form.remember">记住密码</a-checkbox>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                :loading="loading"
                class="submit-btn"
                type="primary"
                @click="handleSubmit"
              >
                确定
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="form-bottm"></div>
      </div>
      <div class="item box-right"></div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

const LoginKey = "login-key";

export default {
  data() {
    return {
      loading: false,
      form: {
        username: "190756",
        password: "qqqqqq",
        remember: false
      }
    };
  },
  methods: {
    setRememberMe() {
      const { username, password, remember } = this.form;
      Cookies.set(
        LoginKey,
        {
          remember: remember,
          username: username,
          password: remember ? password : ""
        },
        { expires: 7 }
      );
    },

    getRememberMe() {
      Object.assign(this.form, JSON.parse(Cookies.get(LoginKey)));
    },
    handleSubmit(e) {
      e.preventDefault();
      this.loading = false;
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
          }, 2000);
          this.$store.dispatch("user/login", this.form).then(res => {
            this.setRememberMe();
            const objReturn = this.passwordValid(this.form.password);
            if (!objReturn.bPwd || objReturn.degreeNumber < 3) {
              this.$confirm({
                content: "你的密码不符合规则，请修改密码？",
                okText: "是",
                cancelText: "否",
                onOk: async () => {
                  this.$router.push("/modifyPassword");
                }
              });
              return;
            }
            this.$router.replace("/");
            // this.$router.replace(this.$route.query.redirect || '/')
          });
        }
      });
    },
    /**
     * 密码验证（密码复杂度：(1)大写字母(2)小写字母(3)数字(4)特殊符号，密码至少需包含上述情形中的三种）
     * @param valPWD输入的密码信息
     * @return 返回验证信息
     **/
    passwordValid(valPwd) {
      let msg = "";
      let degreeNumber = 0;
      let bPwd = false;

      if (
        valPwd == null ||
        valPwd.length < 8 ||
        valPwd.length > 24 ||
        valPwd.trim().length === ""
      ) {
        bPwd = false;
      } else {
        bPwd = true;
      }
      const arrVerify = [
        { regName: "Number", regValue: /^.*[0-9]+.*/ },
        { regName: "LowerCase", regValue: /^.*[a-z]+.*/ },
        { regName: "UpperCase", regValue: /^.*[A-Z]+.*/ },
        { regName: "SpecialCharacters", regValue: /^.*[^a-zA-Z0-9]+.*/ }
      ];
      let regNum = 0; // 记录匹配的次数
      for (let iReg = 0; iReg < arrVerify.length; iReg++) {
        if (arrVerify[iReg].regValue.test(valPwd)) {
          regNum = regNum + 1;
        }
      }
      degreeNumber = regNum;
      if (regNum <= 2) {
        msg = "密码必须包含大小写字母和数字";
      }
      const objReturn = {
        msg: msg,
        degreeNumber: degreeNumber,
        bPwd: bPwd
      };
      return objReturn;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path !== "/login") {
      next(true);
    } else {
      next(false);
    }
  },
  created() {
    this.getRememberMe();
  }
};
</script>

<style scoped lang="less">
@import "./Login.less";
</style>
